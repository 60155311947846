<template>
  <div class="commodity-inventory">
    <div class="main">
      <div class="list">
        <div class="list-item" v-for="(item, index) in list" :key="item.id" @click="itemClick(item, index)">
          <item-card :title="item.stockItemName">
            <div class="content">
              <div class="content-row">
                <div class="content-item" style="width:100%">
                  <span class="name">规格型号:</span>
                  <span>{{ item.specifications }}</span>
                </div>
              </div>
              <div class="content-row">
                <div class="content-item">
                  <span class="name">物品类别:</span>
                  <span>{{ item.stockItemCategoryName }}</span>
                </div>
              </div>
              <div class="content-row">
                <div class="content-item">
                  <span class="name">存放仓库:</span>
                  <span>{{ item.warehouseName }}</span>
                </div>
                <div class="content-item">
                  <span class="name">货位:</span>
                  <span>{{ item.stockSite }}</span>
                </div>
              </div>
              <div class="content-row">
                <div class="content-item">
                  <span class="name">单位:</span>
                  <span>{{ item.measuringUnit }}</span>
                </div>
              </div>
              <div class="content-row">
                <div class="content-item">
                  <span class="name">盘点数量:</span>
                  <span>{{ item.inventoryAmount }}</span>
                </div>
              </div>
            </div>
          </item-card>
        </div>
      </div>
      <van-empty description="暂无数据" v-if="!list.length" />
      <van-popup class="dialog" v-model="showDialog" :close-on-click-overlay="false">
        <van-form ref="dialogForm">
          <span class="dialog-title">{{ dialog.stockItemName }}</span>
          <!-- <van-field v-model="dialog.stockItemName" name="物品" label="物品" readonly /> -->
          <van-field
            v-model="dialog.inventoryAmount"
            placeholder="请填写"
            name="盘点数量"
            label="盘点数量"
            required
            type="digit"
            :rules="[{ required: true, message: '盘点数量不能为空' }]"
          >
          </van-field>
          <div class="action">
            <van-button class="btn" type="info" round size="small" @click="dialogConfirm">确定</van-button>
            <van-button class="btn" type="default" round size="small" native-type="button" @click="dialogCancel">取消</van-button>
          </div>
        </van-form>
      </van-popup>
    </div>
    <div class="footer">
      <van-button class="inventory" type="info" round @click="inventoryConfirm">确定盘点</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityInventory.less";
import { SUCCESS_CODE } from "@/apis/notification";
import { cloneDeep } from "lodash-es";
export default {
  name: "CommodityInventory",
  data() {
    return {
      list: [],
      showDialog: false,
      dialog: {
        stockItemName: "",
        inventoryAmount: ""
      }
    };
  },
  computed: {},
  methods: {
    addStockWarehouing(data) {
      let items = data.map(item => {
        return {
          stockItemId: item.stockItemId,
          warehousingQuantity: item.inventoryAmount - item.inventoryNumber,
          unitPrice: item.unitPrice,
          stockSite: item.stockSite,
          warehouseStockRackId: item.warehouseStockRackId,
          status: "SUBMITTED",
          maxCapacity: item.maxCapacity
        };
      });
      let params = {
        warehousingType: "盘点类型",
        warehouseId: data[0].warehouseId,
        status: "SUBMITTED",
        items
      };
      return this.$api.addStockWarehouing({ params });
    },
    addInventoryChecks(stockWarehousingId = null) {
      let params = {
        checkDetail: JSON.stringify(this.list),
        type: this.$route.query.type
      };
      if (stockWarehousingId) {
        params.stockWarehousingId = stockWarehousingId;
      }
      return this.$api.addInventoryChecks({ params });
    },
    itemClick(item, index) {
      this.showDialog = true;
      this.cIndex = index;
      Object.assign(this.dialog, item);
    },
    dialogConfirm() {
      console.log(this.list[this.cIndex], this.dialog);
      Object.assign(this.list[this.cIndex], this.dialog);
      this.$refs.dialogForm.resetValidation();
      this.showDialog = false;
    },
    dialogCancel() {
      this.dialog = cloneDeep(this.dialogCopy);
      this.$refs.dialogForm.resetValidation();
      this.showDialog = false;
    },
    async inventoryConfirm() {
      if (this.check()) {
        this.$utils.warning("请输入完整数据");
        return;
      }
      let data = this.list.filter(item => {
        return item.inventoryAmount != item.inventoryNumber;
      });
      if (data.length > 0) {
        let ret = await this.addStockWarehouing(data);
        if (ret.code != SUCCESS_CODE) return;
        let ret1 = await this.addInventoryChecks(ret.data.id);
        if (ret1.code != SUCCESS_CODE) return;
        this.$utils.success("盘点成功");
        this.$router.go(-1);
      } else {
        let ret = await this.addInventoryChecks();
        if (ret.code != SUCCESS_CODE) return;
        this.$utils.success("盘点成功");
        this.$router.go(-1);
      }
    },
    check() {
      return this.list.some(item => item.inventoryAmount == "");
    },
    init() {
      let inventoryData = JSON.parse(sessionStorage.getItem("inventoryData")) || [];
      inventoryData.forEach(item => {
        item.inventoryAmount = "";
      });
      this.list = inventoryData;
    }
  },
  created() {
    this.dialogCopy = cloneDeep(this.dialog);
    this.init();
  },
  beforeDestroy() {
    sessionStorage.removeItem("inventoryData");
  }
};
</script>

<style></style>
